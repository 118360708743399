import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { TrackGoogleAnalyticsEvent } from "./GoogleAnalytics";

const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key":
        "008188175e660dbeb07737cf8c3dc576:1364cb55eca25adb988944fe786845fe2341a80b9676d06dc1e5dda0b1bffba2d7691342a55c390dfc39d6a025e14968",
};

if (process.env.NODE_ENV === "development") {
    requestHeaders["x-mock-only"] = "true";
}

export function RequestInformationForm({
    requestInformationFormOpen,
    setRequestInformationFormOpen,
    appWrappedTranslator,
}) {
    const [email, setEmail] = React.useState("");
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertSeverity, setAlertSeverity] = React.useState("info");
    const [emailIsValid, setEmailIsValid] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const handleClickOpen = () => {
        setRequestInformationFormOpen(true);
        setEmail("");
    };

    const handleClose = () => {
        setRequestInformationFormOpen(false);
        setEmail("");
        setLoading(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        handleClose();
    };

    const validateEmail = (event) => {
        const regex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

        if (email.length) {
            setEmailIsValid(regex.test(event.target.value));
        } else {
            setEmailIsValid(null);
        }
    };

    const handleOnSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        TrackGoogleAnalyticsEvent(
            "requestInformationFormSubmit",
            "requestInformationFormSubmitClick",
            "requestInformationFormSubmitLabel"
        );

        try {
            let res = await fetch(`https://api.vyum.ai/api/send-email`, {
                headers: {
                    ...requestHeaders,
                },
                method: "POST",
                body: JSON.stringify({
                    fullName: "1010",
                    email: email,
                    message: "1010",
                    sendtoEmail: "jm@joshuamummert.com",
                    emailSubject: "1010 RST request info form submission",
                }),
            });

            await res.json();

            if (res.status === 200) {
                setAlertMessage(
                    appWrappedTranslator("requestInformationFormTitle")
                );
                setAlertSeverity("success");
            } else {
                appWrappedTranslator("formSubmitError");
                setAlertMessage("Something went wrong! Please try again");
                setAlertSeverity("error");
            }

            handleSnackbarClose();

            setSnackbarOpen(true);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <React.Fragment>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5500}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert style={{ width: "100%" }} severity={alertSeverity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Button
                variant="outlined"
                style={{
                    width: "100%",
                    padding: "2%",
                    marginTop: "2%",
                }}
                onClick={(event) => {
                    event.preventDefault();
                    handleClickOpen();
                    TrackGoogleAnalyticsEvent(
                        "requestInformationButton",
                        "requestInformationButtonClick",
                        "requestInformationButtonLabel"
                    );
                }}
            >
                {appWrappedTranslator("buttonRequestInformation")}
            </Button>
            <Dialog open={requestInformationFormOpen} onClose={handleClose}>
                <DialogTitle>
                    {appWrappedTranslator("requestInformationFormTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {appWrappedTranslator("requestInformationFormBody")}
                    </DialogContentText>
                    <form id="contactForm" onSubmit={handleOnSubmit}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={appWrappedTranslator("formEmail")}
                            type="email"
                            fullWidth
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                                validateEmail(event);
                            }}
                            onBlur={(event) => {
                                validateEmail(event);
                            }}
                            error={
                                (email.length && emailIsValid === false) ||
                                false
                            }
                            color={
                                email.length && emailIsValid
                                    ? "success"
                                    : "primary"
                            }
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {appWrappedTranslator("formCancel")}
                    </Button>
                    <LoadingButton
                        loading={loading}
                        disabled={!email || !emailIsValid}
                        onClick={(event) => {
                            handleOnSubmit(event);
                        }}
                        type="submit"
                    >
                        {appWrappedTranslator("formSubmit")}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

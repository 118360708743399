import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import LanguageIcon from "@mui/icons-material/Language";
import PaletteIcon from "@mui/icons-material/Palette";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Container from "@mui/system/Container";
import * as React from "react";
import packageJson from "../package.json";
import "./App.css";
import { TrackGoogleAnalyticsEvent } from "./GoogleAnalytics";
// import { InitializeGoogleAnalytics } from "./GoogleAnalytics";
import { InitializeGoogleAnalytics } from "./GoogleAnalytics";
import { KpiCard } from "./KpiCard";
import { RequestInformationForm } from "./RequestInformationForm";
import { ShareholderLoginForm } from "./ShareholderLoginForm";
import { languages_nativeSpelling_map, translatorParsed } from "./translator";

const mockLastUpdated = new Date();

mockLastUpdated.setDate(Math.floor(Math.random() * 13) * -1);
mockLastUpdated.setHours(Math.floor(Math.random() * 7));
mockLastUpdated.setMinutes(Math.floor(Math.random() * 13));
mockLastUpdated.setSeconds(Math.floor(Math.random() * 33));

const mockShareholdersValue = Math.floor(Math.random() * (13 - 5 + 1)) + 5;
const mockUnitsValue = Math.floor(Math.random() * (33 - 3 + 1)) + 13;
const mockArtValue = Math.floor(Math.random() * (95 - 70 + 1)) + 330;
const mockAumValue =
    (mockUnitsValue * 150000 + mockArtValue * 1013 + 132000) / 1000000;

function App() {
    const [requestInformationFormOpen, setRequestInformationFormOpen] =
        React.useState(false);

    const trademark = String.fromCodePoint(0x02122);

    const [anchorElLanguageMenu, setAnchorElLanguageMenu] =
        React.useState(null);
    const languageMenuOpen = Boolean(anchorElLanguageMenu);
    const handleLanguageMenuOnClick = (event) => {
        event.preventDefault(event);
        setAnchorElLanguageMenu(event.currentTarget);
    };
    const handleLanguageMenuOnClose = (event) => {
        event.preventDefault(event);
        setAnchorElLanguageMenu(null);

        if (event.target.getAttribute("data-language")) {
            const lang = event.target.getAttribute("data-language");
            const langKey = lang.slice(0, 2).toLowerCase();

            setActiveLanguageKey(langKey);
        }
    };

    const [activeLanguageKey, setActiveLanguageKey] = React.useState("en");

    function appWrappedTranslator(stringKeyToTranslate) {
        return translatorParsed(activeLanguageKey, stringKeyToTranslate);
    }

    const disabledSection = true;

    React.useEffect(() => {
        InitializeGoogleAnalytics();
    });

    return (
        <div className="App">
            <header className="App-header">
                <div className="welcome-text">
                    <Box>
                        <Container
                            maxWidth="xl"
                            disableGutters
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "2%",
                            }}
                        >
                            <div style={{ textAlign: "left" }}>
                                <b>
                                    {appWrappedTranslator("rstTitle")}{" "}
                                    {trademark}
                                </b>
                                <br />
                                {appWrappedTranslator("phlPa")}
                                {/* <br /> */}
                            </div>
                            <div>
                                <Button
                                    id="languageIconButton"
                                    variant="outlined"
                                    style={{
                                        padding: "5px 10px",
                                        margin: "0",
                                        minWidth: "0",
                                    }}
                                    endIcon={<LanguageIcon size="large" />}
                                    aria-controls={
                                        languageMenuOpen
                                            ? "basic-menu"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        languageMenuOpen ? "true" : undefined
                                    }
                                    // onClick={handleLanguageMenuOnClick}
                                    onClick={(event) => {
                                        event.preventDefault(event);

                                        handleLanguageMenuOnClick(event);

                                        TrackGoogleAnalyticsEvent(
                                            "i18nMenu",
                                            "i18nMenuClick",
                                            "i18nMenuClickLabel"
                                        );
                                    }}
                                >
                                    <Typography>
                                        {appWrappedTranslator("localeKey")}
                                    </Typography>
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElLanguageMenu}
                                    open={languageMenuOpen}
                                    onClose={handleLanguageMenuOnClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    {Object.keys(languages_nativeSpelling_map)
                                        .filter(
                                            (language) =>
                                                languages_nativeSpelling_map[
                                                    language
                                                ].code !== activeLanguageKey
                                        )
                                        .sort()
                                        .map((x, i) => (
                                            <MenuItem
                                                onClick={(event) => {
                                                    event.preventDefault();

                                                    TrackGoogleAnalyticsEvent(
                                                        `i18nMenuItem${x}Select`,
                                                        `i18nMenuItem${x}SelectClick`,
                                                        `i18nMenuItem${x}SelectClickLabel`
                                                    );

                                                    handleLanguageMenuOnClose(
                                                        event
                                                    );
                                                }}
                                                key={i}
                                                data-language={x}
                                            >
                                                {
                                                    languages_nativeSpelling_map[
                                                        x
                                                    ].nativeSpelling
                                                }
                                            </MenuItem>
                                        ))}
                                </Menu>
                            </div>
                        </Container>
                    </Box>
                    <hr />
                    {/* <i style={{ fontSize: "calc(5px + 2vmin)" }}>
                        "{appWrappedTranslator("companyTagLine")}"
                    </i>
                    <p>{""}</p> */}

                    <Box>
                        <Container
                            maxWidth="xl"
                            disableGutters
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "2%",
                                margin: "0 0 2% 0",
                            }}
                        >
                            <KpiCard
                                topIcon={<AccountBalanceIcon />}
                                largeCenteredDisplayValue={mockAumValue}
                                bottomPercentageValue={37}
                                phraseKeyWordage={appWrappedTranslator(
                                    "kpiSAum"
                                )}
                                setRequestInformationFormOpen={
                                    setRequestInformationFormOpen
                                }
                                showNoChangeIcon={false}
                            />
                            <KpiCard
                                topIcon={<Diversity2Icon />}
                                largeCenteredDisplayValue={
                                    mockShareholdersValue
                                }
                                bottomPercentageValue={37}
                                phraseKeyWordage={appWrappedTranslator(
                                    "kpiShareholders"
                                )}
                                setRequestInformationFormOpen={
                                    setRequestInformationFormOpen
                                }
                                showNoChangeIcon={false}
                            />
                        </Container>
                        <Container
                            maxWidth="xl"
                            disableGutters
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "2%",
                            }}
                        >
                            <KpiCard
                                topIcon={<ApartmentIcon />}
                                largeCenteredDisplayValue={mockUnitsValue}
                                bottomPercentageValue={18}
                                phraseKeyWordage={appWrappedTranslator(
                                    "kpiUnits"
                                )}
                                setRequestInformationFormOpen={
                                    setRequestInformationFormOpen
                                }
                                showNoChangeIcon={false}
                            />
                            <KpiCard
                                topIcon={<PaletteIcon />}
                                largeCenteredDisplayValue={mockArtValue}
                                bottomPercentageValue={18}
                                phraseKeyWordage={appWrappedTranslator(
                                    "kpiArt"
                                )}
                                setRequestInformationFormOpen={
                                    setRequestInformationFormOpen
                                }
                                showNoChangeIcon={false}
                            />
                        </Container>
                        {!disabledSection && (
                            <p className="subtext" style={{ color: "#2e7d32" }}>
                                <i>
                                    {appWrappedTranslator("kpiLastUpdated")}{" "}
                                    {mockLastUpdated.toLocaleString()}
                                </i>
                            </p>
                        )}
                        {/* @todo add a mock "last updated value," workflow to assign a time value to a given KPI */}
                    </Box>
                    <RequestInformationForm
                        requestInformationFormOpen={requestInformationFormOpen}
                        setRequestInformationFormOpen={
                            setRequestInformationFormOpen
                        }
                        appWrappedTranslator={appWrappedTranslator}
                    />
                    <ShareholderLoginForm
                        appWrappedTranslator={appWrappedTranslator}
                    />
                    <hr />
                    <div>
                        {/* {appWrappedTranslator("circa")} */}
                        {/* <br /> */}
                        <p className="subtext">
                            {appWrappedTranslator("founderName")}{" "}
                            <a
                                href="https://www.joshuamummertstern.com"
                                className="footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                    TrackGoogleAnalyticsEvent(
                                        "founderWebsiteLink",
                                        "founderWebsiteLinkClick",
                                        "founderWebsiteLinkLabel"
                                    );
                                }}
                            >
                                <b>Joshua Mummert-Stern</b>
                            </a>
                        </p>
                        <p className="subtext">
                            {appWrappedTranslator("nrhp")}{" "}
                            <a
                                href="https://www.nps.gov/subjects/nationalregister/database-research.htm#table"
                                target="_blank"
                                className="footer-link"
                                rel="noopener noreferrer"
                            >
                                <b>84003541</b>
                            </a>
                        </p>
                        <hr />
                        <span className="subtext">
                            {
                                "\u00A9 TenTen Race Street Trust. All Right Reserved."
                            }{" "}
                            {new Date().getFullYear()}
                        </span>
                        <span> </span>
                        <span className="subtext">v{packageJson.version}</span>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default App;

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import * as React from "react";

function useInterval(callback, delay) {
    const { useEffect, useRef } = React;
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}

export function KpiCard(props) {
    const {
        topIcon,
        largeCenteredDisplayValue,
        bottomPercentageValue,
        phraseKeyWordage,
        setRequestInformationFormOpen,
        showNoChangeIcon,
    } = props;

    // const [largeCenteredDisplayValueWrangled, setLargeCenteredDisplayValueWrangled] = React.useState(largeCenteredDisplayValue);
    const [
        largeCenteredDisplayValueWrangled,
        setLargeCenteredDisplayValueWrangled,
    ] = React.useState(0);

    const disabledSection = true;

    useInterval(() => {
        if (largeCenteredDisplayValueWrangled < largeCenteredDisplayValue) {
            const diffValue =
                largeCenteredDisplayValue - largeCenteredDisplayValueWrangled;

            let quantifier = 1;

            if (diffValue < 10) {
                quantifier = 1;
            }

            if (diffValue > 10 && diffValue < 50) {
                quantifier = 10;
            }

            if (diffValue >= 50) {
                quantifier = 50;
            }

            setLargeCenteredDisplayValueWrangled(
                largeCenteredDisplayValueWrangled + quantifier
            );
        }
    }, 100);

    const mockAumQuantifier = Number(Math.random().toFixed(1));

    return (
        <Card
            onClick={() => setRequestInformationFormOpen(true)}
            style={{
                flexGrow: 1,
                flex: "1 1 0",
                width: 0,
                justifyContent: "center",
                cursor: "pointer",
            }}
            className="fade-in"
        >
            <CardContent style={{ padding: "10%" }}>
                <SvgIcon fontSize="large">{topIcon}</SvgIcon>
                <Typography
                    variant="h4"
                    style={{
                        fontWeight: "700",
                        padding: "4% 0",
                        // color: "#0288D1",
                    }}
                    component="div"
                    className={"fade-in"}
                >
                    {phraseKeyWordage !== "AUM"
                        ? largeCenteredDisplayValueWrangled
                        : `${
                              largeCenteredDisplayValueWrangled +
                              mockAumQuantifier
                          }M`}
                </Typography>
                {/* <hr /> */}
                <Typography
                    variant="subtitle1"
                    // style={{ borderTop: "1px solid #00ff00" }}
                >
                    {phraseKeyWordage}
                </Typography>
                <Stack
                    style={{
                        justifyContent: "center",
                    }}
                    alignItems="center"
                    direction="row"
                >
                    {!disabledSection && (
                        <>
                            {showNoChangeIcon ? (
                                <>
                                    <SvgIcon
                                        color={"grey"}
                                        fontSize="large"
                                        style={{ width: "0.5em" }}
                                    >
                                        <ArrowDropUpIcon />
                                    </SvgIcon>
                                    <Typography
                                        color={"grey.main"}
                                        variant="body2"
                                    >
                                        0%
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <SvgIcon
                                        color={"success"}
                                        fontSize="large"
                                        style={{ width: "0.5em" }}
                                    >
                                        <ArrowDropUpIcon />
                                    </SvgIcon>
                                    <Typography
                                        color={"success.main"}
                                        variant="body2"
                                    >
                                        {bottomPercentageValue}%
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}

import parse from "html-react-parser";
import i18n_hub from "./i18n.json";

export function translator(langKey, stringKey) {
    return i18n_hub[langKey][stringKey]
        ? i18n_hub[langKey][stringKey]
        : i18n_hub["en"][stringKey] || stringKey;
}

export function translatorParsed(langKey, stringKey) {
    return parse(translator(langKey, stringKey));
}

export const languages_nativeSpelling_map = {
    Chinese: { nativeSpelling: "简化字", code: "ch" },
    English: { nativeSpelling: "English", code: "en" },
    French: { nativeSpelling: "Français", code: "fr" },
    German: { nativeSpelling: "Deutsch", code: "ge" },
    // Russian: { nativeSpelling: "Русский язык", code: "ru" },
    Spanish: { nativeSpelling: "Español", code: "es" },
};

export const languages = [
    "Chinese", // 简化字
    "English",
    "French", // Français
    "German", // Deutsch
    "Russian", // Русский язык
    "Spanish", // Español
];

/**

rstTitle
phlPa
circa
founderName
nrhp
kpiBoard
kpiUnits
kpiShareholders
kpiLastUpdated
formSubmitError
buttonShareholderLogin
shareholderLoginFormTitle
formUsername
formPassword
formEmail
formCancel
formSubmit
buttonRequestInformation
requestInformationFormTitle

===


1010 Race Street Trust
Philadelphia, PA
Since 1984
Founded by
? National Register of Historic Places (acronym)
HOA Board Members
Units
Active Shareholders
Last Updated


Please try again
Shareholder Login
Entering Secure Area
Username
Password
Cancel
Login


Thanks for reaching out! Will be in touch soon
Something went wrong! Please try again
Request Information
Thanks for reaching out!
Please enter your email, the one you actually check! We'll pass along more information about this project when we follow up!
Email
Cancel
Submit


"localeKeyTest": "",
"rstTitle": "",
"phlPa": "",
"circa": "",
"founderName": "",
"nrhp": "",
"kpiBoard": "",
"kpiUnits": "",
"kpiShareholders": "",
"kpiLastUpdated": "",
"formSubmitError": "",
"buttonShareholderLogin": "",
"shareholderLoginFormTitle": "",
"formUsername": "",
"formPassword": "",
"formEmail": "",
"formCancel": "",
"formSubmit": "",
"buttonRequestInformation": "",
"requestInformationFormTitle": "",
*/

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { TrackGoogleAnalyticsEvent } from "./GoogleAnalytics";

export function ShareholderLoginForm({ appWrappedTranslator }) {
    const [open, setOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
        setUsername("");
        setPassword("");
    };

    const handleClose = () => {
        setOpen(false);
        setUsername("");
        setPassword("");
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        handleClose();
    };

    return (
        <React.Fragment>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4500}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                message={appWrappedTranslator("formSubmitError")}
                // style={{ paddingTop: "40px" }}
            >
                <Alert style={{ width: "100%" }} severity="error">
                    {appWrappedTranslator("formSubmitError")}
                </Alert>
            </Snackbar>
            <Button
                variant="contained"
                style={{
                    width: "100%",
                    padding: "2%",
                    // background: "#fff",
                    marginTop: "15px",
                }}
                color="info"
                onClick={(event) => {
                    event.preventDefault();
                    handleClickOpen();
                    TrackGoogleAnalyticsEvent(
                        "shareholderLoginButton",
                        "shareholderLoginButtonClick",
                        "shareholderLoginButtonLabel"
                    );
                }}
            >
                {appWrappedTranslator("buttonShareholderLogin")}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {appWrappedTranslator("shareholderLoginFormTitle")}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label={appWrappedTranslator("formUsername")}
                        type="username"
                        fullWidth
                        value={username}
                        onChange={(event) => {
                            setUsername(event.target.value);
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label={appWrappedTranslator("formPassword")}
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {appWrappedTranslator("formCancel")}
                    </Button>
                    <Button
                        disabled={!username || !password}
                        onClick={(event) => {
                            event.preventDefault();
                            handleSnackbarClose();
                            TrackGoogleAnalyticsEvent(
                                "shareholderLoginFormSubmit",
                                "shareholderLoginFormSubmitClick",
                                "shareholderLoginFormSubmitLabel"
                            );
                            setSnackbarOpen(true);
                        }}
                    >
                        {appWrappedTranslator("formSubmit")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
